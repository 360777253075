// const firebaseConfig = {
//   apiKey: "AIzaSyDMLQ_3WIWE7s18gS_9dbSROQ2HB2CVpok",
//   authDomain: "koperasiku-f5d36.firebaseapp.com",
//   projectId: "koperasiku-f5d36",
//   storageBucket: "koperasiku-f5d36.appspot.com",
//   messagingSenderId: "454834146621",
//   appId: "1:454834146621:web:9eb6c52ec4612d51184954",
//   measurementId: "G-CZ03W6Y8LB",
// };
const firebaseConfig = {
  apiKey: "AIzaSyDMLQ_3WIWE7s18gS_9dbSROQ2HB2CVpok",
  authDomain: "koperasiku-f5d36.firebaseapp.com",
  projectId: "koperasiku-f5d36",
  storageBucket: "koperasiku-f5d36.appspot.com",
  messagingSenderId: "454834146621",
  appId: "1:454834146621:web:6629ba768f7cb48d184954",
  measurementId: "G-N2MFQBZ44B",
};

export default firebaseConfig;
