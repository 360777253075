<template>
  <div id="about-us" class="h-screen relative flex justify-center">
    <div class="bg-green-fluorescent w-full h-1/2 md:h-3/4"></div>
    <div
      class="flex flex-col absolute top-12 md:top-32 z-10 bg-white shadow rounded-3xl w-4/5 h-full p-3 md:p-10 overflow-y-auto text-left"
    >
      <p class="font-bold text-2xl md:text-5xl text-black pb-2 md:pb-6">
        Pengenalan
      </p>
      <div class="flex flex-row">
        <div class="rectangle-23 bg-green-fluorescent mt-1 mr-3"></div>
        <div class="w-full">
          <p class="font-medium text-md md:text-xl pb-2 md:pb-6">
            <!-- Koperasi Pertanian Sabah telah ditubuhkan dengan
            jayanya sejak 14 April 1991. Koperasi ini ditubuhkan untuk
            meningkatkan kepentingan dan taraf ekonomi anggotanya mengikut
            prinsip-prinsip koperasi. -->
            Koperasi Pertanian Sabah ditakrifkan sebagai sebuah organisasi
            autonomi yang keanggotaanya adalah secara sukarela bagi memenuhi
            keperluan dan aspirasi ekonomi, sosial dan budaya anggotanya melalui
            sebuah perniagaan atau perusahaan yang dipunyai bersama dan dikawal
            secara demokrasi
          </p>
          <p class="font-medium text-md md:text-xl">
            Di dalam proses membangunkan koperasi, anggota-anggota Koperasi
            perlu menghayati nilai-nilai etika yang berlandaskan kejujuran,
            sikap terbuka, tanggungjawab kemasyarakatan, dan sikap sayang
            menyayangi orang lain berpandukan kepada prinsip-prinsip Koperasi.
            <!-- Oleh itu, dengan berteraskan pertanian sebagai fungsi utama koperasi
            , koperasi telah menyediakan satu rangkaian aktiviti agar setiap
            anggotanya menikmati kemudahan serta peningkatan ekonomi yang
            dicapai. -->
          </p>
        </div>
      </div>
      <p
        class="font-bold text-2xl md:text-5xl text-black pb-2 md:pb-6 pt-4 md:pt-12"
      >
        Prinsip-Prinsip Koperasi
      </p>
      <div class="flex flex-row">
        <div class="rectangle-23 bg-green-fluorescent mt-1 mr-3"></div>
        <div class="w-full h-1/5">
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            1. Keanggotaan sukarela dan terbuka
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            2. Kawalan demokratik oleh anggota
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            3. Penglibatan ekonomi oleh anggota
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            4. Autonomi dan kebebasan
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            5. Pendidikan, Latihan dan maklumat
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            6. Kerjasama antara koperasi
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            7. Mengambil berat terhadap masyarakat.
          </p>
        </div>
      </div>
      <p
        class="font-bold text-2xl md:text-5xl text-black pb-2 md:pb-6 pt-4 md:pt-12"
      >
        Matlamat Penubuhan Koperasi
      </p>
      <div class="flex flex-row">
        <div class="rectangle-23 bg-green-fluorescent mt-1 mr-3"></div>
        <div class="w-full">
          <p class="font-medium text-md md:text-xl">
            Matlamat Koperasi ini ditubuhkan adalah untuk meningkatkan
            kepentingan dan taraf ekonomi anggotanya mengikut kepentingan
            Koperasi
          </p>
        </div>
      </div>
      <p
        class="font-bold text-2xl md:text-5xl text-black pb-2 md:pb-6 pt-4 md:pt-12"
      >
        Fungsi Koperasi
      </p>
      <div class="flex flex-row">
        <div class="rectangle-23 bg-green-fluorescent mt-1 mr-3"></div>
        <div class="w-full">
          <p class="font-medium text-md md:text-xl">
            Fungsi utama Koperasi adalah PERTANIAN
          </p>
        </div>
      </div>
      <p
        class="font-bold text-2xl md:text-5xl text-black pb-2 md:pb-6 pt-4 md:pt-12"
      >
        Penganugerahan
      </p>
      <div class="flex flex-row">
        <div class="rectangle-23 bg-green-fluorescent mt-1 mr-3"></div>
        <div class="w-full">
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            1. Penarafan Komposit 4 bintang oleh Suruhanjaya Koperasi Malaysia ,
            Kuala Lumpur bagi kedudukan kewangan dan operasi koperasi pada tahun
            2015.
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            2. Hadiah pertama dalam kategori Anugerah Ketua Menteri dalam
            pertandingan Kualiti Koperasi Peringkat Negeri Sabah pada tahun
            1995.
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            3. Hadiah saguhati dalam kategori Anugerah Kualiti Koperasi
            peringkat kebangsaan pada tahun 1995.
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            4. Hadiah pertama dalam kategori Anugerah Kualiti Koperasi Pekerja
            dalam pertandingan Kualiti Koperasi peringkat Negeri Sabah tahun
            2003.
          </p>
          <p class="font-medium text-md md:text-xl pb-6 md:pb-12">
            5. Mendapat anugerah sektor pertanian (2024) oleh YB Datuk Ewon
            Benedick selaku Menteri Pembangunan Usahawan dan Koperasi.
          </p>
        </div>
      </div>
      <p class="font-bold text-2xl md:text-5xl text-black pb-2 md:pb-6">
        Kepimpinan Tertinggi dan Pengurusan
      </p>
      <div class="flex flex-row">
        <div class="rectangle-23 bg-green-fluorescent mt-1 mr-3"></div>
        <div class="w-full">
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            1. ENCIK CHONG TAN CHUN , PENGERUSI
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            2. ENCIK FRANK SALAZAR, TIMBALAN PENGERUSI
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            3. TUAN HAJI JUPRI BIN DEMABELA , SETIAUSAHA
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            4. TUAN HAJI PAKEN BIN HAJI ANDAY , BENDAHARI
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            5. DATUK IDRUS BIN SHAFIE , ANGGOTA LEMBAGA
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            6. DATUK HAJI MC ISMAIL BIN SALAM , ANGGOTA LEMBAGA
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            7. ENCIK DR ABDUL RAHIM BIN AWANG , ANGGOTA LEMBAGA
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            8. TUAN HAJI LAGANI BIN HAJI SAHID , ANGGOTA LEMBAGA
          </p>
          <p class="font-medium text-md md:text-xl pb-6 md:pb-12">
            9. ENCIK ASHRAF BIN YACOB KHAN , ANGGOTA LEMBAGA
          </p>
        </div>
      </div>
      <h1 class="font-bold text-2xl md:text-5xl text-black pb-3 md:pb-6">
        Jawatankuasa Audit Dalam
      </h1>
      <div class="flex flex-row h-48">
        <div class="rectangle-23 bg-green-fluorescent mt-1 mr-3"></div>
        <div class="w-full">
          <p class="font-normal text-md md:text-xl pb-2 md:pb-5">
            1. ENCIK WAHID BIN SAPOON
          </p>
          <p class="font-normal text-md md:text-xl pb-2 md:pb-5">
            2. ENCIK TCHUI PENG KONG
          </p>
          <p class="font-normal text-md md:text-xl pb-6 md:pb-12">
            3. PUAN ROSIAH BINTI YENTENG
          </p>
        </div>
      </div>
      <h1
        class="font-bold text-2xl md:text-5xl font-semibold text-black pb-2 md:pb-6"
      >
        Faedah Keanggotaan
      </h1>
      <div class="flex flex-row">
        <div class="rectangle-23 bg-green-fluorescent mt-1 mr-3"></div>
        <div class="w-full">
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            1. Dividen : Dividen pada kadar menarik diagih-agihkan kepada
            anggota setiap tahun.
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            2. Tabung Pendidikan : Sumbangan untuk setiap anak-anak anggota yang
            cemerlang di dalam peperiksaan utama persekolahan.
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            3. Tabung Perayaan : Sumbagan hadiah perayaan kepada setiap anggota
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            4. Tabung Komitmen : Bantuan menarik kepada ahli anggota yang
            memberikan komitmen yang baik.
          </p>
          <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
            5. Khairat Kematian : Sumbangan khairat kematian untuk anggota dan
            keluarga terdekat seperti isteri atau suami, anak dan ibubapa.
          </p>
          <p class="font-medium text-md md:text-xl pb-6 md:pb-12">
            6. Latihan dan Kursus : Anggota berpeluang untuk menjalani kursus
            anjuran Suruhanjaya Koperasi Malaysia, Maktab Koperasi Sabah dan
            penganjur lain.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<style>
.bg-green-fluorescent {
  background-color: #94de2a;
}
.shadow {
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
}
.rectangle-23 {
  width: 18px;
  height: 18px;
  margin: 31px 19px 20px 4px;
  /* background-color: #94de2a; */
}
</style>
