<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div>
    <Header2 />
    <ProfileHeader />
    <ProfileForm />
    <Footer />
  </div>
</template>

<script>
import Header2 from "../components/Header2.vue";
import ProfileHeader from "../components/ProfileHeader.vue";
import ProfileForm from "../components/ProfileForm.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "UserProfile",
  components: {
    Header2,
    ProfileHeader,
    ProfileForm,
    Footer,
  },
  data: function() {
  }
};
</script>

<style>
</style>
