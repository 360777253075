<!-- <template>
  <div id="activity" class="relative w-full py-10 md:py-20 px-0 md:px-10">
    <h1 class="px-10 font-bold text-2xl md:text-5xl text-black py-3 md:py-6">
      Aktiviti Utama
    </h1>
    <div class="flex flex-row h-40 md:h-20 px-5 md:px-10">
      <div class="rectangle-23 bg-green-fluorescent mt-1 mr-3"></div>
      <div class="w-full h-20">
        <p class="font-medium text-md md:text-xl pb-5">
          1. Membangun tanah dengan aktiviti perladangan atau pertanian secara
          Komersial.
        </p>
        <p class="font-medium text-md md:text-xl pb-5">
          2. Menjalankan aktiviti berkaitan dengan perusahaan ternakan binatang
          dan unggas.
        </p>
        <p class="font-medium text-md md:text-xl pb-5">
          3. Mendapatkan dan menjalankan kerja-kerja kontrak yang berkaitan
          dengan penyelenggaraan ladang, membekal benih-benih pertanian dan
          ternakan, pemasaran dan pemprosesan produk-produk pertaniandan
          ternakan.
        </p>
        <p class="font-medium text-md md:text-xl pb-5">
          4. Menjalankan aktiviti kebajikan anggota , pendidikan dan perniagaan.
        </p>
        <p class="font-medium text-md md:text-xl pb-5">
          5. Membeli, menjual, memindahkan hak milik, mendirikan, menyewa,
          mencagar, memajak, menggadai dan memiliki harta alih dan harta tak
          alih
        </p>
        <p class="font-medium text-md md:text-xl pb-5">
          6. Melaburkan wang berlebihan dalam perkara yang dibenarkan menurut
          syeksyen 54 akta;
        </p>
        <p class="font-medium text-md md:text-xl pb-5">
          7. Mengadakan subsidiari menurut seksyen 19 akta dengan kelulusan
          suruhanjaya.
        </p>
        <p class="font-medium text-md md:text-xl pb-5">
          8. Menyertai usahasama dan menjadi anggota kepada koperasi-koperasi
          lain.
        </p>
        <p class="font-medium text-md md:text-xl pb-5">
          Mengadakan aktiviti lain yang berfaedah dengan kelulusan mesyuarat
          agung.
        </p>
      </div>
    </div>
  </div>
  // Carousel
  <div class="pt-10">
    <Carousel :autoplay="2500" :wrap-around="true" :settings="settings">
      <Slide :key="slide">
        <div class="carousel__item p-5">
          <img
            class="rounded-xl h-44 md:h-96 w-full"
            src="@/assets/images/kelapa-pandan.jpeg"
            alt=""
          />
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item p-5">
          <img
            class="rounded-xl h-44 md:h-96 w-full"
            src="@/assets/images/kelapa-tacunan.jpeg"
            alt=""
          />
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item p-5">
          <img
            class="rounded-xl h-44 md:h-96 w-full"
            src="@/assets/images/thumbdrive/aktiviti-pembersihan.jpeg"
            alt=""
          />
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item p-5">
          <img
            class="rounded-xl h-44 md:h-96 w-full"
            src="@/assets/images/thumbdrive/nenas.jpeg"
            alt=""
          />
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item p-5">
          <img
            class="rounded-xl h-44 md:h-96 w-full"
            src="@/assets/images/thumbdrive/aktiviti-lawatan.jpeg"
            alt=""
          />
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item p-5">
          <img
            class="rounded-xl h-44 md:h-96 w-full"
            src="@/assets/images/thumbdrive/kolam.jpeg"
            alt=""
          />
        </div>
      </Slide>

      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>
</template> -->

<template>
  <div id="activity" class="relative w-full ">
    <!-- Section: Aktiviti Utama -->
    <h1 class="px-10 font-bold text-2xl md:text-5xl pt-10 text-black py-3 md:py-6">Aktiviti Utama</h1>
    <div class="flex flex-row md:flex-row h-auto px-10 md:px-10">
      <div class="rectangle-23 bg-green-fluorescent mt-1 mr-3"></div>
      <div class="w-full">
        <p v-for="(text, index) in mainActivities" :key="index" class="font-medium text-md md:text-xl pb-5">{{ text }}</p>
      </div>
    </div>

    <!-- Section: Aktiviti yang dijalankan -->
    <h1 class="px-10 font-bold text-2xl md:text-5xl text-black py-3 md:py-6">Aktiviti yang dijalankan</h1>
    <div class="flex flex-row md:flex-row h-auto px-10 md:px-10">
      <div class="rectangle-23 bg-green-fluorescent mt-1 mr-3"></div>
      <div class="w-full">
        <p v-for="(activity, index) in conductedActivities" :key="index" class="font-medium text-md md:text-xl pb-5">{{ activity }}</p>
      </div>
    </div>

    <!-- Carousel -->
    <div class="pt-10">
      <Carousel :items-to-show="2.0" :autoplay="2500" :wrap-around="true">
        <Slide v-for="(image, index) in carouselImages" :key="index">
          <div class="carousel__item p-5">
            <img :src="require(`@/assets/images/${image}`)" class="rounded-xl h-44 md:h-96 w-full" alt="" />
          </div>
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "Activity",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      mainActivities: [
        "1. Membangun tanah dengan aktiviti perladangan atau pertanian secara Komersial.",
        "2. Menjalankan aktiviti berkaitan dengan perusahaan ternakan binatang dan unggas.",
        "3. Mendapatkan dan menjalankan kerja-kerja kontrak yang berkaitan dengan penyelenggaraan ladang, membekal benih-benih pertanian dan ternakan, pemasaran dan pemprosesan produk-produk pertaniandan ternakan.",
        "4. Menjalankan aktiviti kebajikan anggota , pendidikan dan perniagaan.",
        "5. Membeli, menjual, memindahkan hak milik, mendirikan, menyewa, mencagar, memajak, menggadai dan memiliki harta alih dan harta tak alih.",
        "6. Melaburkan wang berlebihan dalam perkara yang dibenarkan menurut syeksyen 54 akta;",
        "7. Mengadakan subsidiari menurut seksyen 19 akta dengan kelulusan suruhanjaya.",
        "8. Menyertai usahasama dan menjadi anggota kepada koperasi-koperasi lain.",
        "9. Mengadakan aktiviti lain yang berfaedah dengan kelulusan mesyuarat agung.",
      ],
      conductedActivities: [
        "1. Tapak semaian anak benih sawit seluas 2.5 ekar di tanah yang terletak di Batu 20, Jalan Bukit Quoin, Tawau.",
        "2. Seluas 485 ekar jumlah keseluruhan tanaman sawit di jalankan. Antaranya, tanah yang terletak di Batu 20, Jalan Bukit Quoin, Tawau (84 ekar), Kalumpang, Tawau (178 ekar), Sg. Apas, Tawau (7 ekar), dan Batu 12, Jalan Apas Tawau (216 ekar).",
        "3. Kebun tanaman kelapa pandan seluas 5 ekar di tanah yang terletak di Batu 20, Jalan Bukit Quoin, Tawau.",
        "4. Tanaman Sawit seluas 100 ekar di tanah yang terletak di daerah Telupid.",
        "5. Penanaman Sawit seluas 16 ekar di tanah dalam daerah Beluran.",
        "6. Tanaman Kelapa Tacunan seluas 72.4 ekar di tanah Batu 12, Jalan Apas (62.5 ekar) dan Batu 22, Jalan Bukit Quoin, Tawau (9.9 ekar)",
        "7. Tanaman Nenas MD2 seluas 2.5 ekar di tanah yang terletak di Batu 20 Jalan Bukit Quoin, Tawau",
        "8. Tapak semaian Kelapa Tacunan di tanah Batu 12, Jalan Apas, Tawau.",
        "9. Tapak semaian Durian dan buah-buahan terpilih di tanah Batu 19, Jalan Bukit Quoin, Tawau.",
      ],
      carouselImages: [
        "kelapa-pandan.jpeg",
        "kelapa-tacunan.jpeg",
        "thumbdrive/aktiviti-pembersihan.jpeg",
        "thumbdrive/nenas.jpeg",
        "thumbdrive/aktiviti-lawatan.jpeg",
        "thumbdrive/kolam.jpeg",
        "thumbdrive/ladang-bt-13-1.jpeg",
        "thumbdrive/ladang-bt-13-2.jpeg",
        "thumbdrive/skim-pengesahan-bahan-tanaman-1.jpeg",
        "thumbdrive/skim-pengesahan-bahan-tanaman-2.jpeg",
        "thumbdrive/tapak-semaian-kelapa-sawit-1.jpeg",
        "thumbdrive/tapak-semaian-kelapa-sawit-2.jpeg",
        "thumbdrive/tapak-semaian-kelapa-sawit-bt-19.jpeg",
        "thumbdrive/tapak-semaian-kelapa-tacunan-1.jpeg",
        "thumbdrive/tapak-semaian-kelapa-tacunan-3.jpeg",
        "thumbdrive/tapak-semaian-kelapa-tacunan-4.jpeg",
      ],
    };
  },
};
</script>


<style></style>
