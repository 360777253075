<template>
  <div id="awards" class="w-full relative">
    <h1 class="px-10 font-bold text-2xl md:text-5xl text-black py-3 md:py-6">Penganugerahan</h1>
    <div class="flex flex-row px-10">
      <!-- Bold -->
      <div class="rectangle-23 bg-green-fluorescent mt-1 mr-3"></div>
      <!-- Text Container -->
      <div class="w-full h-20">
        <p class="font-medium text-md md:text-xl pb-2 md:pb-5">
          Koperasi Pertanian Sabah telah menerima pelbagai penghargaan dan
          penganugerahan sebagai salah satu Koperasi terbaik di seluruh negara.
        </p>
      </div>
    </div>
    <div class="w-full h-full px-10 pt-16 md:pt-10">
      <Carousel :autoplay="4500" :wrap-around="true" :settings="settings">
        <Slide :key="slide">
          <div class="carousel__item w-full md:w-3/5 p-5">
            <img
              src="@/assets/images/penganugerahan/Assets-Award-01.png"
              alt=""
            />
            <div class="bg-white">
              <p class="font-medium text-sm md:text-xl hidden text-black pt-5">
                Anugerah Kualiti Koperasi
              </p>
            </div>
          </div>
        </Slide>
        <Slide :key="slide">
          <div class="carousel__item w-full md:w-3/5 p-5">
            <img
              src="@/assets/images/penganugerahan/Assets-Award-02.png"
              alt=""
            />
            <div class="bg-white">
              <p class="font-medium text-sm md:text-xl hidden overflow text-black pt-5">
                Anugerah Kualiti Koperasi Peringkat Negeri Sabah
              </p>
            </div>
          </div>
        </Slide>
        <Slide :key="slide">
          <div class="carousel__item w-full md:w-3/5 p-5">
            <img
              src="@/assets/images/penganugerahan/Assets-Award-04.png"
              alt=""
            />
            <div class="bg-white">
              <p class="font-medium text-sm md:text-xl hidden text-black pt-5">Top 350 Koperasi (2021)</p>
            </div>
          </div>
        </Slide>
        <Slide :key="slide">
          <div class="carousel__item w-full md:w-3/5 p-5">
            <img
              src="@/assets/images/penganugerahan/Assets-Award-03.png"
              alt=""
            />
            <div class="bg-white">
              <p class="font-medium text-sm md:text-xl hidden text-black pt-5">
                Anugerah Kualiti Koperasi (2003)
              </p>
            </div>
          </div>
        </Slide>
        <Slide :key="slide">
          <div class="carousel__item w-full md:w-3/5 p-5">
            <img
              src="@/assets/images/penganugerahan/Assets-Award-05.jpg"
              alt=""
            />
            <div class="bg-white">
              <p class="font-medium text-sm md:text-xl hidden text-black pt-5">
                Anugerah Sektor Pertanian (2024)
              </p>
            </div>
          </div>
        </Slide>

        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import { Carousel, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "Awards",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      settings: {
        itemsToShow: 1.5,
        snapAlign: "center",
      },
    };
  },
  computed: {
    bgImage() {
      return require("@/assets/images/icon-penganugerahan.png");
    },
    image() {
      return {
        background: `url(${this.bgImage})`,
        backgroundSize: "cover !important",
        display: "block",
      };
    },
  },
};
</script>
