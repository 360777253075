<template>
  <div class="m-auto">
    <div>
      <div class="flex flex-col mr-10 md:mr-0 ml-10 md:ml-20">
        <div class="pt-5 font-medium text-2xl">
          <span>MAKLUMAT PERIBADI</span>
        </div>
        <div class="my-6">
          <button
            variant="flat-primary"
            class="flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-black bg-green-fluorescent hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            @click="$refs.file.click()"
          >
            <span class="align-middle">Tukar Gambar Profil </span>
          </button>
          <br v-if="imageData" />
          <div class="flex flex-row items-center">
            <button
              v-if="imageData"
              variant="flat-success"
              @click="onUpload"
              class="flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-black bg-green-fluorescent hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500F"
            >
              <span class="align-middle">Upload</span>
            </button>
            <h1
              v-if="imageData != null"
              class="block text-sm pl-10 font-medium text-gray-700"
            >
              {{ imageData.name }}
            </h1>
          </div>
          <input
            ref="file"
            type="file"
            accept="image/*"
            style="display: none"
            @change="previewImage"
          />
          <br />

          <form @submit.prevent="submit()" class="space-y-6">
            <!-- Full Name -->
            <div>
              <label
                for="full-name"
                class="block text-sm font-medium text-gray-700"
              >
                Nama Penuh
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="user.full_name"
                  id="fullName"
                  name="fullName"
                  type="fullName"
                  autocomplete="fullName"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- NRIC -->
            <div>
              <label for="nric" class="block text-sm font-medium text-gray-700">
                No. Kad Pengenalan
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="user.ic_no"
                  id="nric"
                  name="nric"
                  type="nric"
                  autocomplete="nric"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Address -->
            <div>
              <label
                for="address"
                class="block text-sm font-medium text-gray-700"
              >
                Alamat
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="user.address"
                  id="address"
                  name="address"
                  type="address"
                  autocomplete="address"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- City -->
            <div>
              <label
                for="address"
                class="block text-sm font-medium text-gray-700"
              >
                Poskod
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="user.postcode"
                  id="postcode"
                  name="postcode"
                  type="postcode"
                  autocomplete="postcode"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Negeri -->
            <div>
              <label
                for="state"
                class="block text-sm font-medium text-gray-700"
              >
                Bandar
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="user.city"
                  id="city"
                  name="city"
                  type="city"
                  autocomplete="city"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Address -->
            <div>
              <label
                for="address"
                class="block text-sm font-medium text-gray-700"
              >
                Negeri
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="user.state"
                  id="state"
                  name="state"
                  type="state"
                  autocomplete="state"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Gender -->
            <div>
              <label
                for="gender"
                class="block text-sm font-medium text-gray-700"
              >
                Jantina
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="user.gender"
                  id="gender"
                  name="gender"
                  type="gender"
                  autocomplete="gender"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Phone No. -->
            <div>
              <label
                for="phone-no"
                class="block text-sm font-medium text-gray-700"
              >
                No. Telefon
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="user.phone_number"
                  id="phone-no"
                  name="phone-no"
                  type="phone-no"
                  autocomplete="phone-no"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Email -->
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="user.email"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <div class="pt-5 font-medium text-2xl">
              <span>MAKLUMAT WARIS</span>
            </div>
            <div>
              <label
                for="full-name"
                class="block text-sm font-medium text-gray-700"
              >
                Nama Penuh
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="heirsInformation.full_name"
                  id="fullName"
                  name="fullName"
                  type="fullName"
                  autocomplete="fullName"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- NRIC -->
            <div>
              <label for="nric" class="block text-sm font-medium text-gray-700">
                No. Kad Pengenalan
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="heirsInformation.no_ic"
                  id="nric"
                  name="nric"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Address -->
            <div>
              <label
                for="address"
                class="block text-sm font-medium text-gray-700"
              >
                Alamat
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="heirsInformation.address"
                  id="address"
                  name="address"
                  type="address"
                  autocomplete="address"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- City -->
            <div>
              <label
                for="address"
                class="block text-sm font-medium text-gray-700"
              >
                Poskod
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="heirsInformation.postcode"
                  id="postcode"
                  name="postcode"
                  type="postcode"
                  autocomplete="postcode"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Negeri -->
            <div>
              <label
                for="state"
                class="block text-sm font-medium text-gray-700"
              >
                Bandar
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="heirsInformation.city"
                  id="city"
                  name="city"
                  type="city"
                  autocomplete="city"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Address -->
            <div>
              <label
                for="address"
                class="block text-sm font-medium text-gray-700"
              >
                Negeri
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="heirsInformation.state"
                  id="state"
                  name="state"
                  type="state"
                  autocomplete="state"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Gender -->
            <div>
              <label
                for="gender"
                class="block text-sm font-medium text-gray-700"
              >
                Jantina
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="heirsInformation.gender"
                  id="gender"
                  name="gender"
                  type="gender"
                  autocomplete="gender"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Phone No. -->
            <div>
              <label
                for="phone-no"
                class="block text-sm font-medium text-gray-700"
              >
                No. Telefon
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="heirsInformation.phone_number"
                  id="phone-no"
                  name="phone-no"
                  type="phone-no"
                  autocomplete="phone-no"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Email -->
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="heirsInformation.email"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <div class="items-center text-2xl font-Medium">
              MAKLUMAT AKAUN BANK WARIS
            </div>
            <!-- Account Holder -->
            <div>
              <label
                for="acc-holder"
                class="block text-sm font-medium text-gray-700"
              >
                Nama Pemegang Akaun
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="heirsInformation.account_holder_name"
                  id="acc-holder"
                  name="acc-holder"
                  type="acc-holder"
                  autocomplete="acc-holder"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Account No. -->
            <div>
              <label
                for="acc-no"
                class="block text-sm font-medium text-gray-700"
              >
                No. Akaun
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="heirsInformation.bank_account"
                  id="acc-no"
                  name="acc-no"
                  type="acc-no"
                  autocomplete="acc-no"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Bank Name -->
            <div>
              <label
                for="bank-issuer"
                class="block text-sm font-medium text-gray-700"
              >
                Nama Bank
              </label>
              <div class="mt-1 w-full md:w-2/5">
                <input
                  v-model="heirsInformation.bank_name"
                  id="bank-issuer"
                  name="bank-issuer"
                  type="bank-issuer"
                  autocomplete="bank-issuer"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- Register Button -->
            <!-- Todo: @click here to redirect -->
            <div class="mb-10">
              <button
                type="submit"
                class="w-full md:w-2/5 flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-black bg-green-fluorescent hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { doc, getDoc, setDoc, collection, updateDoc } from "firebase/firestore";
import { db } from "@/firebase/db";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "@firebase/storage";
// import { uploadBytes, ref as stRef, storage } from "@firebase/storage";
// import Swal from 'sweetalert2'
// const Swal = require('sweetalert2')
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  data() {
    return {
      user: {},
      heirsInformation: {
        full_name: "",
        ic_no: "",
        address: "",
        postcode: "",
        city: "",
        state: "",
        gender: "",
        phone_number: "",
        email: "",
        account_holder_name: "",
        bank_account: "",
        bank_name: "",
      },
      heir: {},
      share: {},
      imageData: null,
    };
  },
  async created() {
    this.checkUserState();
  },
  methods: {
    async submit() {
      this.showSlideOver = !this.showSlideOver;
      //?? Check if heir information already exist
      if (this.user && this.user.heir_id) {
        const heirInformation = await getDoc(
          doc(db, "heirs", this.user.heir_id)
        );
        //?? If exist, update the information
        if (heirInformation) {
          const heirsDocRef = doc(db, "heirs", this.user.heir_id);
          this.heirsInformation.updated_at = new Date();

          const heirsDocSnap = await updateDoc(
            heirsDocRef,
            this.heirsInformation
          );

          console.log([heirsDocSnap]);
        }
      } else {
        const heirsDocRef = doc(collection(db, "heirs"));
        const heirsDocSnap = await setDoc(heirsDocRef, this.heirsInformation);
        console.log([heirsDocSnap]);
        this.user.heir_id = heirsDocRef.id;
      }

      const userDocRef = doc(db, "users", this.user.id);

      const userDocSnap = await updateDoc(userDocRef, this.user);
      Swal.fire({
        title: "Tahniah!",
        text: "Data anda telah berjaya dikemaskini.",
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#94de2a",
      });
      console.log([userDocSnap]);
      this.checkUserState();
    },
    checkUserState() {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          console.log("Authenticated");
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists) {
            console.log(userDocSnap.data());
            this.user = userDocSnap.data();
            if (this.user.heir_id) {
              const heirInformationDocSnap = await getDoc(
                doc(db, "heirs", this.user.heir_id)
              );
              if (heirInformationDocSnap.exists) {
                this.heirsInformation = heirInformationDocSnap.data();
                console.log(this.heirsInformation);
              }
            }
            if (this.user.share_id) {
              const shareInformationDocSnap = await getDoc(
                doc(db, "shares", this.user.share_id)
              );
              if (shareInformationDocSnap.exists) {
                this.share = shareInformationDocSnap.data();
                console.log(this.share);
              }
            }
          }
        } else {
          console.log("Not authenticated");
          this.$router.push("/");
        }
      });
    },
    onUpload() {
      const storage = getStorage();
      const firebaseCurrentUser = getAuth().currentUser;
      const lastDot = this.imageData.name.lastIndexOf(".");
      const ext = this.imageData.name.substring(lastDot + 1);
      const storageRef = ref(storage, `${firebaseCurrentUser.uid}.${ext}`);
      const uploadTask = uploadBytesResumable(storageRef, this.imageData);
      uploadTask.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.error(error.message);
        },
        () => {
          this.avatarRefresh = true;
          this.uploadValue = 100;
          getDownloadURL(storageRef).then(async (url) => {
            this.user.imageUrl = url;
            const userDocRef = doc(db, "users", this.user.id);
            await updateDoc(userDocRef, { image_url: this.user.imageUrl });
            Swal.fire({
              title: "Tahniah!",
              text: "Gambar profil anda telah berjaya dikemaskini.",
              icon: "success",
              confirmButtonText: "OK",
              confirmButtonColor: "#94de2a",
            });
          });
        }
      );
    },

    previewImage(event) {
      console.log(event);
      this.uploadValue = 0;
      const imageData = event.target.files[0];
      this.imageData = imageData;
    },
  },
};
</script>
