<template>
  <section
    v-bind:style="image"
    class="w-full h-screen px-3 antialiased bg-green-500 lg:px-6"
  >
    <div class="mx-auto h-full">
      <nav
        class="flex items-center w-full h-24 select-none"
        x-data="{ showMenu: false }"
      >
        <div
          class="
            static
            flex flex-wrap
            items-center
            justify-between
            w-full
            h-full
            mx-auto
            font-medium
            md:justify-center
          "
        >
          <div class="flex items-center justify-items-start w-2/5 md:w-1/5">
            <div
              class="w-10 md:w-20 h-8 md:h-20 m-0 md:m-5"
              v-bind:style="logoImage"
            ></div>
          </div>

          <!-- menu -->
          <div
            class="
              fixed
              top-0
              left-0
              items-center
              hidden
              w-full
              h-24
              p-3
              text-xl
              bg-gray-900 bg-opacity-50
              md:text-sm
              lg:text-base
              md:w-3/4 md:bg-transparent md:p-0 md:relative md:flex
            "
            :class="{ flex: showMenu, hidden: !showMenu }"
          >
            <div
              class="
                flex-col
                w-full
                h-auto h-full
                overflow-hidden
                bg-white
                rounded-lg
                select-none
                md:bg-transparent
                md:rounded-none
                md:relative
                md:flex
                md:flex-row
                md:overflow-auto
              "
            >
              <div
                class="
                  flex flex-col
                  items-center
                  justify-center
                  w-full
                  h-full
                  mt-12
                  text-center text-indigo-700
                  md:text-indigo-200
                  md:w-2/3
                  md:mt-0
                  md:flex-row
                  md:items-center
                "
              >
                <a
                  href="#about-us"
                  class="
                    inline-block
                    px-0 px-4
                    py-2
                    mx-2
                    font-medium
                    text-left
                    md:px-0
                    hover:text-green
                    md:hover:text-white
                    lg:mx-3
                    md:text-center
                  "
                  >PENGENALAN</a
                >
                <a
                  href="#product"
                  class="
                    inline-block
                    px-0 px-4
                    py-2
                    mx-2
                    font-medium
                    text-left
                    md:px-0
                    hover:text-white
                    md:hover:text-white
                    lg:mx-3
                    md:text-center
                  "
                  >PRODUK</a
                >
                <a
                  href="#activity"
                  class="
                    inline-block
                    px-0 px-4
                    py-2
                    mx-2
                    font-medium
                    text-left
                    md:px-0
                    hover:text-white
                    md:hover:text-white
                    lg:mx-3
                    md:text-center
                  "
                  >AKTIVITI</a
                >
                <a
                  href="#awards"
                  class="
                    inline-block
                    px-0 px-4
                    py-2
                    mx-2
                    font-medium
                    text-left
                    md:px-0
                    hover:text-white
                    md:hover:text-white
                    lg:mx-3
                    md:text-center
                  "
                  >PENGANUGERAHAN</a
                >
                <router-link
                  to="/contact"
                  href="#"
                  class="
                    inline-block
                    px-0 px-4
                    py-2
                    mx-2
                    font-medium
                    text-left
                    md:px-0
                    hover:text-white
                    md:hover:text-white
                    lg:mx-3
                    md:text-center
                  "
                  >HUBUNGI KAMI</router-link
                >
              </div>
              <div
                class="
                  animate-pulse
                  flex
                  items-center
                  justify-center
                  w-full
                  h-full
                  pt-4
                  md:w-1/3 md:flex-row md:py-0
                "
              >
                <div class="flex -space-x-2 overflow-hidden">
                  <img
                    v-if="user.image_url"
                    @click="showSlideOverOrRedirect"
                    class="
                      inline-block
                      m-3
                      h-12
                      w-12
                      rounded-full
                      ring-2 ring-green-500
                    "
                    :src="user.image_url"
                    alt="avatar-default"
                  />
                  <img
                    v-else
                    @click="showSlideOverOrRedirect"
                    class="
                      inline-block
                      m-3
                      h-12
                      w-12
                      rounded-full
                      ring-2 ring-green-500
                    "
                    src="@/assets/images/user.png"
                    alt="avatar-default"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Mobile Menu -->
          <div
            @click="showMobileMenu = !showMobileMenu"
            class="
              absolute
              right-0
              z-50
              flex flex-col
              items-end
              w-10
              h-10
              p-2
              mr-4
              rounded-full
              cursor-pointer
              md:hidden
              hover:bg-gray-900 hover:bg-opacity-10
            "
            :class="{ 'text-gray-400': showMenu, 'text-gray-100': !showMenu }"
          >
            <svg
              class="w-6 h-6"
              x-show="!showMenu"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              x-cloak=""
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </div>
          <!--
    Mobile menu, show/hide based on mobile menu state.

    Entering: "duration-200 ease-out"
      From: "opacity-0 scale-95"
      To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
      From: "opacity-100 scale-100"
      To: "opacity-0 scale-95"
  -->
          <div
            v-if="showMobileMenu"
            class="
              absolute
              top-0
              inset-x-0
              p-2
              transition
              transform
              origin-top-right
              md:hidden
              z-40
            "
          >
            <div
              class="
                rounded-lg
                shadow-lg
                ring-1 ring-black ring-opacity-5
                bg-white
                divide-y-2 divide-gray-50
              "
            >
              <div class="pt-5 pb-6 px-5">
                <div class="flex items-center justify-between">
                  <div>
                    <img
                      class="h-8 w-auto"
                      src="@/assets/images/logo-primary.png"
                      alt="Workflow"
                    />
                  </div>
                  <div class="-mr-2">
                    <button
                      @click="showMobileMenu = !showMobileMenu"
                      type="button"
                      class="
                        bg-white
                        rounded-md
                        p-2
                        inline-flex
                        items-center
                        justify-center
                        text-gray-400
                        hover:text-gray-500 hover:bg-gray-100
                        focus:outline-none
                        focus:ring-2
                        focus:ring-inset
                        focus:ring-indigo-500
                      "
                    >
                      <span class="sr-only">Close menu</span>
                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="mt-6">
                  <nav class="grid gap-y-8">
                    <a
                      href="#about-us"
                      class="
                        -m-3
                        p-3
                        flex
                        items-center
                        rounded-md
                        hover:bg-gray-50
                      "
                    >
                      <svg
                        class="flex-shrink-0 h-6 w-6 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                        />
                      </svg>
                      <span class="ml-3 text-base font-medium text-gray-900">
                        Pengenalan
                      </span>
                    </a>

                    <a
                      href="#product"
                      class="
                        -m-3
                        p-3
                        flex
                        items-center
                        rounded-md
                        hover:bg-gray-50
                      "
                    >
                      <svg
                        class="flex-shrink-0 h-6 w-6 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                        />
                      </svg>
                      <span class="ml-3 text-base font-medium text-gray-900">
                        Produk
                      </span>
                    </a>

                    <div
                      href="#activity"
                      class="
                        -m-3
                        p-3
                        flex
                        items-center
                        rounded-md
                        hover:bg-gray-50
                      "
                    >
                      <svg
                        class="flex-shrink-0 h-6 w-6 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                        />
                      </svg>
                      <span
                        @click="showFormOption = !showFormOption"
                        class="ml-3 text-base font-medium text-gray-900"
                      >
                        Aktiviti
                      </span>
                    </div>

                    <a
                      href="#awards"
                      class="
                        -m-3
                        p-3
                        flex
                        items-center
                        rounded-md
                        hover:bg-gray-50
                      "
                    >
                      <svg
                        class="flex-shrink-0 h-6 w-6 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                        />
                      </svg>
                      <span class="ml-3 text-base font-medium text-gray-900">
                        Penganugerahan
                      </span>
                    </a>

                    <div
                      href=""
                      class="
                        -m-3
                        p-3
                        flex
                        items-center
                        rounded-md
                        hover:bg-gray-50
                      "
                    >
                      <svg
                        class="flex-shrink-0 h-6 w-6 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        />
                      </svg>
                      <span class="ml-3 text-base font-medium text-gray-900">
                        <router-link
                          to="/contact"
                          href="#"
                          class="text-base font-medium hover:text-gray-900"
                        >
                          Hubungi Kami
                        </router-link>
                      </span>
                    </div>
                    <div>
                      <button
                        @click="mobileSignIn"
                        class="
                          w-full
                          flex
                          items-center
                          justify-center
                          px-4
                          py-2
                          border border-transparent
                          rounded-md
                          shadow-sm
                          text-base
                          font-medium
                          text-white
                          bg-green-500
                          hover:bg-indigo-700
                        "
                      >
                        Log Masuk
                      </button>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <!-- Authentication Slide Over -->
      <div
        v-if="showSlideOver"
        class="z-30 absolute inset-0 overflow-hidden"
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true"
      >
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="
              absolute
              inset-0
              bg-gray-500 bg-opacity-75
              transition-opacity
            "
            aria-hidden="true"
          ></div>
          <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <div class="relative w-screen max-w-md">
              <!-- Close Button -->
              <div
                class="
                  absolute
                  top-0
                  left-0
                  -ml-8
                  pt-4
                  pr-2
                  flex
                  sm:-ml-10 sm:pr-4
                "
              >
                <button
                  type="button"
                  @click="showSlideOver = !showSlideOver"
                  class="
                    rounded-md
                    text-gray-300
                    hover:text-white
                    focus:outline-none focus:ring-2 focus:ring-white
                  "
                >
                  <span class="sr-only">Close panel</span>
                  <!-- Heroicon name: outline/x -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div
                class="
                  h-full
                  flex flex-col
                  py-6
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="mt-6 relative flex-1 px-4 sm:px-6">
                  <!-- Slide-over content -->
                  <div class="absolute inset-0 px-4 sm:px-6">
                    <div class="h-full flex" aria-hidden="true">
                      <!-- Sign In Content -->
                      <!-- TODO: Set condition for sign in as 1st time user or not -->
                      <div class="m-auto">
                        <div
                          v-if="isLoggedIn && isFirstTimeUser && !isLoad"
                          class="flex-col"
                        >
                          <div class="items-center text-3xl font-semibold">
                            Maklumat Peribadi
                          </div>
                          <div class="my-6">
                            <form class="space-y-6">
                              <!-- Full Name -->
                              <div>
                                <label
                                  for="full-name"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  Nama Penuh
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="registrationInformation.fullName"
                                    id="fullName"
                                    name="fullName"
                                    type="fullName"
                                    autocomplete="fullName"
                                    required
                                    class="
                                      appearance-none
                                      block
                                      w-full
                                      px-3
                                      py-2
                                      border border-gray-300
                                      rounded-md
                                      shadow-sm
                                      placeholder-gray-400
                                      focus:outline-none
                                      focus:ring-green-500
                                      focus:border-green-500
                                      sm:text-sm
                                    "
                                  />
                                  <div
                                    v-if="
                                      v$.registrationInformation.fullName.$error
                                    "
                                    class="text-sm font-medium text-red-500"
                                  >
                                    Sila isi ruangan ini
                                  </div>
                                </div>
                              </div>
                              <!-- NRIC -->
                              <div>
                                <label
                                  for="nric"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  No. Kad Pengenalan
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="registrationInformation.icNo"
                                    id="nric"
                                    name="nric"
                                    type="nric"
                                    autocomplete="nric"
                                    required
                                    class="
                                      appearance-none
                                      block
                                      w-full
                                      px-3
                                      py-2
                                      border border-gray-300
                                      rounded-md
                                      shadow-sm
                                      placeholder-gray-400
                                      focus:outline-none
                                      focus:ring-green-500
                                      focus:border-green-500
                                      sm:text-sm
                                    "
                                  />
                                  <div
                                    v-if="
                                      v$.registrationInformation.icNo.$error
                                    "
                                    class="text-sm font-medium text-red-500"
                                  >
                                    Sila isi ruangan ini
                                  </div>
                                </div>
                              </div>
                              <!-- Address -->
                              <div>
                                <label
                                  for="address"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  Alamat
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="registrationInformation.address"
                                    id="address"
                                    name="address"
                                    type="address"
                                    autocomplete="address"
                                    required
                                    class="
                                      appearance-none
                                      block
                                      w-full
                                      px-3
                                      py-2
                                      border border-gray-300
                                      rounded-md
                                      shadow-sm
                                      placeholder-gray-400
                                      focus:outline-none
                                      focus:ring-green-500
                                      focus:border-green-500
                                      sm:text-sm
                                    "
                                  />
                                  <div
                                    v-if="
                                      v$.registrationInformation.address.$error
                                    "
                                    class="text-sm font-medium text-red-500"
                                  >
                                    Sila isi ruangan ini
                                  </div>
                                </div>
                              </div>
                              <!-- Gender -->
                              <div>
                                <label
                                  for="gender"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  Jantina
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="registrationInformation.gender"
                                    id="gender"
                                    name="gender"
                                    type="gender"
                                    autocomplete="gender"
                                    required
                                    class="
                                      appearance-none
                                      block
                                      w-full
                                      px-3
                                      py-2
                                      border border-gray-300
                                      rounded-md
                                      shadow-sm
                                      placeholder-gray-400
                                      focus:outline-none
                                      focus:ring-green-500
                                      focus:border-green-500
                                      sm:text-sm
                                    "
                                  />
                                  <div
                                    v-if="
                                      v$.registrationInformation.gender.$error
                                    "
                                    class="text-sm font-medium text-red-500"
                                  >
                                    Sila isi ruangan ini
                                  </div>
                                </div>
                              </div>
                              <!-- Phone No. -->
                              <div>
                                <label
                                  for="phone-no"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  No. Telefon
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="
                                      registrationInformation.phoneNumber
                                    "
                                    id="phone-no"
                                    name="phone-no"
                                    type="phone-no"
                                    autocomplete="phone-no"
                                    required
                                    class="
                                      appearance-none
                                      block
                                      w-full
                                      px-3
                                      py-2
                                      border border-gray-300
                                      rounded-md
                                      shadow-sm
                                      placeholder-gray-400
                                      focus:outline-none
                                      focus:ring-green-500
                                      focus:border-green-500
                                      sm:text-sm
                                    "
                                  />
                                  <div
                                    v-if="
                                      v$.registrationInformation.phoneNumber
                                        .$error
                                    "
                                    class="text-sm font-medium text-red-500"
                                  >
                                    Sila isi ruangan ini
                                  </div>
                                </div>
                              </div>
                              <!-- Email -->
                              <div>
                                <label
                                  for="email"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  Email
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="registrationInformation.email"
                                    id="email"
                                    name="email"
                                    type="email"
                                    autocomplete="email"
                                    required
                                    class="
                                      appearance-none
                                      block
                                      w-full
                                      px-3
                                      py-2
                                      border border-gray-300
                                      rounded-md
                                      shadow-sm
                                      placeholder-gray-400
                                      focus:outline-none
                                      focus:ring-green-500
                                      focus:border-green-500
                                      sm:text-sm
                                    "
                                  />
                                  <div
                                    v-if="
                                      v$.registrationInformation.email.$error
                                    "
                                    class="text-sm font-medium text-red-500"
                                  >
                                    Sila isi ruangan ini
                                  </div>
                                </div>
                              </div>

                              <!-- Password -->
                              <div>
                                <label
                                  for="password"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  Kata Laluan Baharu
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="registrationInformation.password"
                                    id="password"
                                    name="password"
                                    type="password"
                                    autocomplete="password"
                                    required
                                    class="
                                      appearance-none
                                      block
                                      w-full
                                      px-3
                                      py-2
                                      border border-gray-300
                                      rounded-md
                                      shadow-sm
                                      placeholder-gray-400
                                      focus:outline-none
                                      focus:ring-green-500
                                      focus:border-green-500
                                      sm:text-sm
                                    "
                                  />
                                  <div
                                    v-if="
                                      v$.registrationInformation.password.$error
                                    "
                                    class="text-sm font-medium text-red-500"
                                  >
                                    Sila isi ruangan ini
                                  </div>
                                </div>
                              </div>
                              <div>
                                <label
                                  for="password"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  Sahkan Kata Laluan Baharu
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="
                                      registrationInformation.confirmPassword
                                    "
                                    id="password"
                                    name="password"
                                    type="password"
                                    autocomplete="password"
                                    required
                                    oninput="check(this)"
                                    class="
                                      appearance-none
                                      block
                                      w-full
                                      px-3
                                      py-2
                                      border border-gray-300
                                      rounded-md
                                      shadow-sm
                                      placeholder-gray-400
                                      focus:outline-none
                                      focus:ring-green-500
                                      focus:border-green-500
                                      sm:text-sm
                                    "
                                  />
                                  <div
                                    v-if="
                                      v$.registrationInformation.confirmPassword
                                        .$error
                                    "
                                    class="text-sm font-medium text-red-500"
                                  >
                                    Sila isi ruangan ini
                                  </div>
                                </div>
                              </div>
                              <div class="items-center text-3xl font-semibold">
                                Maklumat Akaun Bank
                              </div>
                              <!-- Account Holder -->
                              <div>
                                <label
                                  for="acc-holder"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  Nama Pemegang Akaun
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="bankInformation.accountHolderName"
                                    id="acc-holder"
                                    name="acc-holder"
                                    type="acc-holder"
                                    autocomplete="acc-holder"
                                    required
                                    class="
                                      appearance-none
                                      block
                                      w-full
                                      px-3
                                      py-2
                                      border border-gray-300
                                      rounded-md
                                      shadow-sm
                                      placeholder-gray-400
                                      focus:outline-none
                                      focus:ring-green-500
                                      focus:border-green-500
                                      sm:text-sm
                                    "
                                  />
                                  <div
                                    v-if="
                                      v$.bankInformation.accountHolderName
                                        .$error
                                    "
                                    class="text-sm font-medium text-red-500"
                                  >
                                    Sila isi ruangan ini
                                  </div>
                                </div>
                              </div>
                              <!-- Account No. -->
                              <div>
                                <label
                                  for="acc-no"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  No. Akaun
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="bankInformation.accountNo"
                                    id="acc-no"
                                    name="acc-no"
                                    type="acc-no"
                                    autocomplete="acc-no"
                                    required
                                    class="
                                      appearance-none
                                      block
                                      w-full
                                      px-3
                                      py-2
                                      border border-gray-300
                                      rounded-md
                                      shadow-sm
                                      placeholder-gray-400
                                      focus:outline-none
                                      focus:ring-green-500
                                      focus:border-green-500
                                      sm:text-sm
                                    "
                                  />
                                  <div
                                    v-if="v$.bankInformation.accountNo.$error"
                                    class="text-sm font-medium text-red-500"
                                  >
                                    Sila isi ruangan ini
                                  </div>
                                </div>
                              </div>
                              <!-- Bank Name -->
                              <div>
                                <label
                                  for="bank-issuer"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  Nama Bank
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="bankInformation.bankName"
                                    id="bank-issuer"
                                    name="bank-issuer"
                                    type="bank-issuer"
                                    autocomplete="bank-issuer"
                                    required
                                    class="
                                      appearance-none
                                      block
                                      w-full
                                      px-3
                                      py-2
                                      border border-gray-300
                                      rounded-md
                                      shadow-sm
                                      placeholder-gray-400
                                      focus:outline-none
                                      focus:ring-green-500
                                      focus:border-green-500
                                      sm:text-sm
                                    "
                                  />
                                  <div
                                    v-if="v$.bankInformation.bankName.$error"
                                    class="text-sm font-medium text-red-500"
                                  >
                                    Sila isi ruangan ini
                                  </div>
                                </div>
                              </div>
                              <!-- Register Button -->
                              <div class="mb-10">
                                <!-- Todo: @click here to redirect -->
                                <button
                                  @click="signUp"
                                  class="
                                    w-full
                                    flex
                                    justify-center
                                    py-2
                                    px-4
                                    border border-transparent
                                    rounded-full
                                    shadow-sm
                                    text-sm
                                    font-medium
                                    text-black
                                    bg-green-fluorescent
                                    hover:opacity-80
                                    focus:outline-none
                                    focus:ring-2
                                    focus:ring-offset-2
                                    focus:ring-green-500
                                  "
                                >
                                  Daftar
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div v-if="!isLoggedIn" class="text-left">
                          <div class="text-5xl font-semibold">Selamat</div>
                          <div class="text-5xl font-semibold">Datang</div>
                          <div class="text-lg pt-2">
                            Sila masukkan Emel dan Kata Laluan
                          </div>
                          <div class="text-lg -mt-2">untuk log masuk</div>
                          <div class="mt-6">
                            <form class="space-y-6">
                              <!-- Email Address -->
                              <div>
                                <label
                                  for="email"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  Emel
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="loginInformation.email"
                                    id="email"
                                    name="email"
                                    type="email"
                                    autocomplete="email"
                                    required
                                    class="
                                      appearance-none
                                      block
                                      w-full
                                      px-3
                                      py-2
                                      border border-gray-300
                                      rounded-md
                                      shadow-sm
                                      placeholder-gray-400
                                      focus:outline-none
                                      focus:ring-green-500
                                      focus:border-green-500
                                      sm:text-sm
                                    "
                                  />
                                </div>
                              </div>
                              <!-- Password -->
                              <div class="space-y-1">
                                <label
                                  for="password"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-700
                                  "
                                >
                                  Kata Laluan
                                </label>
                                <div class="mt-1">
                                  <input
                                    v-model="loginInformation.password"
                                    id="password"
                                    name="password"
                                    type="password"
                                    autocomplete="current-password"
                                    required
                                    class="
                                      appearance-none
                                      block
                                      w-full
                                      px-3
                                      py-2
                                      border border-gray-300
                                      rounded-md
                                      shadow-sm
                                      placeholder-white
                                      focus:outline-none
                                      focus:ring-green-500
                                      focus:border-green-500
                                      sm:text-sm
                                    "
                                  />
                                </div>
                              </div>
                              <!-- Submit Button -->
                              <div class="pb-10">
                                <!-- Todo: @click here to redirect -->
                                <button
                                  @click="signIn"
                                  class="
                                    w-full
                                    flex
                                    justify-center
                                    py-2
                                    px-4
                                    border border-transparent
                                    rounded-full
                                    shadow-sm
                                    text-sm
                                    font-medium
                                    text-black
                                    bg-green-fluorescent
                                    hover:opacity-80
                                    focus:outline-none
                                    focus:ring-2
                                    focus:ring-offset-2
                                    focus:ring-green-500
                                  "
                                >
                                  Log Masuk
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Landing -->
      <div class="container py-32 my-auto mx-auto text-left sm:px-4">
        <h1
          class="
            text-xl
            md:text-2xl
            font-extrabold
            tracking-tight
            text-white
            sm:text-2xl sm:leading-none
            md:text-4xl
            xl:text-2xl
          "
        >
          <span class="block">SELAMAT DATANG KE LAMAN RASMI</span>
        </h1>
        <h1
          class="
            text-5xl
            md:text-2xl
            font-extrabold
            tracking-tight
            text-white
            sm:text-2xl sm:leading-none
            md:text-4xl
            xl:text-7xl
          "
        >
          <span class="block">KOPERASI</span>
        </h1>
        <h1
          class="
            text-5xl
            md:text-2xl
            font-extrabold
            tracking-tight
            text-white
            sm:text-2xl sm:leading-none
            md:text-4xl
            xl:text-7xl
          "
        >
          <span class="block"> PERTANIAN</span>
        </h1>
        <h1
          class="
            text-5xl
            md:text-2xl
            font-extrabold
            tracking-tight
            text-white
            sm:text-4xl sm:leading-none
            md:text-3xl
            xl:text-7xl
          "
        >
          <span class="block">SABAH BERHAD</span>
        </h1>
      </div>
    </div>
  </section>

  <!-- Arrow Bottom -->
  <div class="relative">
    <a href="#about-us">
      <svg
        class="h-10 mx-auto -mt-10"
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 316.1 143.58"
      >
        <path
          fill="#94de2a"
          fill-opacity="1"
          id="Path_5"
          data-name="Path 5"
          class="cls-1"
          d="M0,143.49s27.81, 0,82.78-84.11,129-67.41,164.66, 0,68.66,84.11,68.66,84.11Z"
        />
        <path
          fill="#1c2343"
          fill-opacity="1"
          id="Icon_awesome-arrow-down"
          data-name="Icon awesome-arrow-down"
          class="cls-2 animate-bounce"
          d="M189.36,76.48,191.87,79a2.71,2.71,0,0,1,0,3.83l0,0-22,22a2.71,2.71,0,0,1-3.83,0l0,0-22-22A2.7,2.7,0,0,1,144,79l0,0,2.52-2.52a2.72,2.72,0,0,1,3.84,0l0,0,13,13.63V57.64a2.71,2.71,0,0,1,2.7-2.71h3.64a2.71,2.71,0,0,1,2.71,2.7h0V90.15l13-13.63a2.69,2.69,0,0,1,3.82-.11l.06.06Z"
        />
      </svg>
    </a>
  </div>
</template>

<script>
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

import { db } from "@/firebase/db";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "Header",

  componenets: {},
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data: () => ({
    showMenu: false,
    showSlideOver: false,
    isFirstTimeUser: true,
    isLoggedIn: false,
    isLoad: false,
    showMobileMenu: false,
    loginInformation: {
      email: "",
      password: "",
    },
    registrationInformation: {
      fullName: "",
      icNo: "",
      address: "",
      password: "",
      confirmPassword: "",
      gender: "",
      phoneNumber: "",
      email: "",
    },
    bankInformation: {
      accountHolderName: "",
      accountNo: "",
      bankName: "",
    },
    user: {},
  }),

  validations() {
    return {
      registrationInformation: {
        fullName: { required },
        icNo: { required },
        address: { required },
        password: { required },
        confirmPassword: { required },
        gender: { required },
        phoneNumber: { required },
        email: { required },
      },
      bankInformation: {
        accountHolderName: { required },
        accountNo: { required },
        bankName: { required },
      },
    };
  },

  computed: {
    bgImage() {
      return require("@/assets/images/header-banner.png");
    },
    logoWhite() {
      return require("@/assets/images/logo-white.png");
    },
    logoImage() {
      return {
        background: `url(${this.logoWhite})`,
        backgroundSize: "cover !important",
        display: "block",
      };
    },
    image() {
      return {
        background: `url(${this.bgImage})`,
        backgroundSize: "cover !important",
        display: "block",
      };
    },
  },
  created() {
    this.checkUserState();
  },
  methods: {
    mobileSignIn() {
      this.showMobileMenu = !this.showMobileMenu;
      this.showSlideOverOrRedirect();
    },
    async signIn() {
      // const auth = getAuth();
      console.log(this.loginInformation);
      const auth = getAuth();
      if (
        this.loginInformation.password != "" &&
        this.loginInformation.email != ""
      ) {
        this.isLoggedIn = true;
        this.isLoad = true;

        if (this.loginInformation.password === "password") {
          this.isLoad = false;
          console.log(this.loginInformation.password);
        } else {
          const signInUser = await signInWithEmailAndPassword(
            auth,
            this.loginInformation.email,
            this.loginInformation.password
          );
          console.log(this.isFirstTimeUser);
          console.log(signInUser);
          if (!this.isFirstTimeUser) {
            this.$router.push("/user-profile");
            this.isLoad = false;
          }
        }
      }

      // const signInUser = await signInWithEmailAndPassword(
      //   auth,
      //   this.loginInformation.email,
      //   this.loginInformation.password
      // );

      // if (signInUser) {
      //   this.user = signInUser;
      //   if (!this.isFirstTImeUser) {
      //     this.$router.push("/user-profile");
      //   }
      // } else {
      //   if (this.loginInformation.password == "password") {
      //     const newUser = await createUserWithEmailAndPassword(
      //       auth,
      //       this.loginInformation.email,
      //       this.loginInformation.password
      //     );

      //     console.log(newUser);
      //   }
      // }
      // console.log(signInUser);

      // this.isLoggedIn = true;
    },
    async signUp() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      if (this.loginInformation.email !== this.registrationInformation.email) {
        window.alert("Email tidak sama");
      } else {
        this.showSlideOver = !this.showSlideOver;

        const auth = getAuth();
        const newUser = await createUserWithEmailAndPassword(
          auth,
          this.registrationInformation.email,
          this.registrationInformation.password
        );
        console.log(newUser.user.uid);
        const userId = newUser.user.uid;
        const userPayload = {
          full_name: this.registrationInformation.fullName,
          address: this.registrationInformation.address,
          ic_no: this.registrationInformation.icNo,
          phone_number: this.registrationInformation.phoneNumber,
          gender: this.registrationInformation.gender,
          email: this.registrationInformation.email,
          bank_account: this.bankInformation.accountNo,
          bank_name: this.bankInformation.bankName,
          account_holder_name: this.bankInformation.accountHolderName,
          id: userId,
          status: true,
          created_at: new Date(),
          updated_at: new Date(),
        };

        //todo check if admin has already added user email.

        const userDocsRef = await getDocs(
          query(
            collection(db, "users"),
            where("email", "==", userPayload.email)
          )
        );

        if (userDocsRef.empty) {
          const userDocRef = doc(db, "users", userId);

          await setDoc(userDocRef, userPayload);
        } else {
          //Transfer the found data to a new doc.
          const userDataCreatedByAdmin = userDocsRef.docs[0].data();
          if (
            userDataCreatedByAdmin.share_id != undefined &&
            userDataCreatedByAdmin.share_id != ""
          ) {
            userPayload.share_id = userDataCreatedByAdmin.share_id;
          }

          if (
            userDataCreatedByAdmin.heir_id != undefined &&
            userDataCreatedByAdmin.heir_id != ""
          ) {
            userPayload.heir_id = userDataCreatedByAdmin.heir_id;
          }

          //Delete the corresponding data.
          await deleteDoc(doc(db, "users", userDataCreatedByAdmin.id));

          //save the new data from the data created by admin
          const userDocRef = doc(db, "users", userId);

          await setDoc(userDocRef, userPayload);
        }

        this.isFirstTimeUser = false;
        this.$router.push("/user-profile");
      }
    },
    check(input) {
      if (input.value != document.getElementById("password").value) {
        input.setCustomValidity("Password Must be Matching.");
      } else {
        // input is valid -- reset the error message
        input.setCustomValidity("");
      }
    },
    register() {
      // TODO: Run some function here
      this.isLoggedIn = true;
      this.isFirstTImeUser = false;
      this.showSlideOver = !this.showSlideOver;
      if (!this.isFirstTImeUser) {
        this.$router.push("/user-profile");
      }
    },

    checkUserState() {
      const auth = getAuth();

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          await onSnapshot(doc(db, "users", user.uid), async (userDocSnap) => {
            this.user = userDocSnap.data();
            if (userDocSnap.exists) {
              console.log(userDocSnap.data());
              this.user = userDocSnap.data();
              if (this.user.share_id) {
                const shareInformationDocSnap = await getDoc(
                  doc(db, "shares", this.user.share_id)
                );
                if (shareInformationDocSnap.exists) {
                  this.share = shareInformationDocSnap.data();
                  console.log(this.share);
                }
              }
            }
          });
        } else {
          console.log("Not authenticated");
          this.$router.push("/");
        }
      });
    },

    showSlideOverOrRedirect() {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.isLoggedIn = true;
          this.isFirstTimeUser = false;
          this.user = user;

          this.$router.push("/user-profile");
        } else {
          this.showSlideOver = !this.showSlideOver;
        }
      });
    },
  },
};
</script>

<style>
.bg-green-fluorescent {
  background-color: #94de2a;
}
.shadow {
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
}
.rectangle-23 {
  width: 18px;
  height: 18px;
  margin: 31px 19px 288px 4px;
  /* background-color: #94de2a; */
}
</style>
