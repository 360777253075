<template>
  <div class="m-auto">
    <div class="flex flex-col ml-10 md:ml-20">
      <div class="py-5 font-medium text-2xl">
        <span>HUBUNGI KAMI</span>
      </div>
      <div class="w-full pr-10">
        <GoogleMap
          class="h-80"
          api-key="AIzaSyDMLQ_3WIWE7s18gS_9dbSROQ2HB2CVpok"
          style="height: 400px"
          :center="center"
          :zoom="15"
        >
          <InfoWindow :options="{ position: center, content: 'Here' }">
            <div id="contet">
              <div id="bodyContent">
                <h1>
                  <b>Koperasi Pertanian Sabah</b>
                </h1>
              </div>
            </div>
          </InfoWindow>
          <Marker :options="{ position: center }" />
        </GoogleMap>
        <div class="text-3xl font-semibold py-5">Koperasi Pertanian Sabah</div>
        <div class="text-xl font-thin">TB 4543, Blok H, Lot 68</div>
        <div class="text-xl font-thin">
          Tingkat 1, Pusat Komersial Ba Zhong,
        </div>
        <div class="text-xl font-thin">
          Peti Surat 61358, 91023 Tawau, Sabah
        </div>
        <div class="text-xl font-thin pt-5">
          Tel/ Fax/ Hp : 089-774543/ 089-753754/ 016-8374543
        </div>
        <div class="text-xl font-thin py-5">Emel: kpsb92@gmail.com</div>
        <div class="flex space-x-4 pb-10">
          <button @click="redirectToFacebookPage" class="facebook-button">
            Facebook
          </button>
          <button @click="openWhatsAppInNewTab" class="whatsapp-button">
            WhatsApp
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GoogleMap, Marker, InfoWindow } from "vue3-google-map";
export default {
  components: { GoogleMap, Marker, InfoWindow },
  setup() {
    const center = { lat: 4.2440089433851504, lng: 117.89606534992966 };

    return { center };
  },
  methods: {
    redirectToFacebookPage() {
      // URL of your Facebook page
      const facebookPageUrl = "https://www.facebook.com/koperasi.p.berhad";
      // Redirect the user to the Facebook page
      // window.location.href = facebookPageUrl;
      window.open(facebookPageUrl, "_blank");
    },
    openWhatsAppInNewTab() {
      // WhatsApp URL with a phone number or chat link
      const whatsappUrl = "https://wa.me/+60168374543"; // Replace with your number
      // Open WhatsApp in a new tab
      window.open(whatsappUrl, "_blank");
    },
  },
  // data: () => {
  //   return {
  //     center: { lat: 4.2440089433851504, lng: 117.89606534992966 },
  //     markers: [
  //       {
  //         position: {
  //           lat: 4.2440089433851504,
  //           lng: 117.89606534992966,
  //         },
  //       }, // Along list of clusters
  //     ],
  //   };
  // },
};
</script>
<style scoped>
.facebook-button {
  @apply bg-blue-600 text-white py-2 px-4 rounded-md text-lg;
}

.facebook-button:hover {
  @apply bg-blue-700;
}

.whatsapp-button {
  @apply bg-green-500 text-white py-2 px-4 rounded-md text-lg;
}

.whatsapp-button:hover {
  @apply bg-green-600;
}
</style>

<!-- VueGoogleMaps, {
    load: {
      key: "AIzaSyDMLQ_3WIWE7s18gS_9dbSROQ2HB2CVpok",
    },
  } -->
