<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div
        class="
          flex
          justify-between
          items-center
          border-b-2 border-gray-100
          py-6
          md:justify-start md:space-x-10
        "
      >
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <router-link to="/">
            <span class="sr-only">primary-logo</span>
            <img
              class="h-8 w-auto sm:h-10"
              src="@/assets/images/logo-primary.png"
              alt=""
            />
          </router-link>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <button
            type="button"
            @click="showMobileMenu = !showMobileMenu"
            class="
              bg-white
              rounded-md
              p-2
              inline-flex
              items-center
              justify-center
              text-gray-400
              hover:text-gray-500 hover:bg-gray-100
              focus:outline-none
              focus:ring-2
              focus:ring-inset
              focus:ring-indigo-500
            "
            aria-expanded="false"
          >
            <span class="sr-only">Open menu</span>
            <!-- Heroicon name: outline/menu -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <nav class="hidden md:flex space-x-10">
          <div class="relative">
            <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" -->
            <button
              type="button"
              class="
                text-gray-500
                group
                bg-white
                rounded-md
                inline-flex
                items-center
                text-base
                font-medium
                hover:text-gray-900
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
              "
              aria-expanded="false"
            >
              <router-link to="/">Halaman Utama</router-link>
            </button>
          </div>

          <!-- <a
            href="#"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Pricing
          </a> -->
          <router-link
            to="/contact"
            href="#"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Hubungi Kami
          </router-link>

          <div class="relative">
            <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" -->
            <button
              v-if="isShowButton"
              type="button"
              @click="showMenu = !showMenu"
              class="
                text-gray-500
                group
                bg-white
                rounded-md
                inline-flex
                items-center
                text-base
                font-medium
                hover:text-gray-900
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-green-500
              "
              aria-expanded="false"
            >
              <span>Borang</span>
              <svg
                class="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>

            <!--
            'More' flyout menu, show/hide based on flyout menu state.

            Entering: "transition ease-out duration-200"
              From: "opacity-0 translate-y-1"
              To: "opacity-100 translate-y-0"
            Leaving: "transition ease-in duration-150"
              From: "opacity-100 translate-y-0"
              To: "opacity-0 translate-y-1"
          -->
            <div
              v-if="showMenu"
              class="
                absolute
                z-10
                left-1/2
                transform
                -translate-x-1/2
                mt-3
                px-2
                w-screen
                max-w-md
                sm:px-0
              "
            >
              <div
                class="
                  rounded-lg
                  shadow-lg
                  ring-1 ring-black ring-opacity-5
                  overflow-hidden
                "
              >
                <div
                  class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8"
                >
                  <a
                    href="/KPSB-BorangPermohonanAnggota.pdf"
                    target="_blank"
                    class="
                      -m-3
                      p-3
                      flex
                      items-start
                      rounded-lg
                      hover:bg-gray-50
                    "
                  >
                    <svg
                      class="flex-shrink-0 h-6 w-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                      />
                    </svg>
                    <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                        Permohonan Anggota
                      </p>
                    </div>
                  </a>

                  <a
                    href="/KPSB-BorangWarisAnggota.pdf"
                    target="_blank"
                    class="
                      -m-3
                      p-3
                      flex
                      items-start
                      rounded-lg
                      hover:bg-gray-50
                    "
                  >
                    <!-- Heroicon name: outline/bookmark-alt -->
                    <svg
                      class="flex-shrink-0 h-6 w-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                        Waris Anggota
                      </p>
                    </div>
                  </a>

                  <a
                    href="/KPSB-BorangPermohonanTambahSyerAnggota.pdf"
                    target="_blank"
                    class="
                      -m-3
                      p-3
                      flex
                      items-start
                      rounded-lg
                      hover:bg-gray-50
                    "
                  >
                    <!-- Heroicon name: outline/calendar -->
                    <svg
                      class="flex-shrink-0 h-6 w-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                        Permohonan Tambah Syer Anggota
                      </p>
                    </div>
                  </a>

                  <a
                    href="/KPSB-BorangPermohonanTebusSyerAnggota.pdf"
                    target="_blank"
                    class="
                      -m-3
                      p-3
                      flex
                      items-start
                      rounded-lg
                      hover:bg-gray-50
                    "
                  >
                    <svg
                      class="flex-shrink-0 h-6 w-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                      />
                    </svg>
                    <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                        Permohonan Tebus Syer Anggota
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <button
            type="button"
            v-if="isShowButton"
            @click="logOut"
            class="
              ml-8
              whitespace-nowrap
              inline-flex
              items-center
              justify-center
              px-4
              py-2
              border border-transparent
              rounded-md
              shadow-sm
              text-base
              font-medium
              text-white
              bg-green-500
              hover:bg-green-600
            "
          >
            Log Keluar
          </button>
        </div>
      </div>
    </div>

    <!--
    Mobile menu, show/hide based on mobile menu state.

    Entering: "duration-200 ease-out"
      From: "opacity-0 scale-95"
      To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
      From: "opacity-100 scale-100"
      To: "opacity-0 scale-95"
  -->
    <div
      v-if="showMobileMenu"
      class="
        absolute
        top-0
        inset-x-0
        p-2
        transition
        transform
        origin-top-right
        md:hidden
        z-40
      "
    >
      <div
        class="
          rounded-lg
          shadow-lg
          ring-1 ring-black ring-opacity-5
          bg-white
          divide-y-2 divide-gray-50
        "
      >
        <div class="pt-5 pb-6 px-5">
          <div class="flex items-center justify-between">
            <div>
              <img
                class="h-8 w-auto"
                src="@/assets/images/logo-primary.png"
                alt="Workflow"
              />
            </div>
            <div class="-mr-2">
              <button
                @click="showMobileMenu = !showMobileMenu"
                type="button"
                class="
                  bg-white
                  rounded-md
                  p-2
                  inline-flex
                  items-center
                  justify-center
                  text-gray-400
                  hover:text-gray-500 hover:bg-gray-100
                  focus:outline-none
                  focus:ring-2
                  focus:ring-inset
                  focus:ring-indigo-500
                "
              >
                <span class="sr-only">Close menu</span>
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="mt-6">
            <nav class="grid gap-y-8">
              <a
                href="#"
                class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
              >
                <svg
                  class="flex-shrink-0 h-6 w-6 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                  />
                </svg>
                <span class="ml-3 text-base font-medium text-gray-900">
                  <router-link to="/">Halaman Utama</router-link>
                </span>
              </a>

              <a
                href="#"
                class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
              >
                <svg
                  class="flex-shrink-0 h-6 w-6 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                  />
                </svg>
                <span class="ml-3 text-base font-medium text-gray-900">
                  <router-link
                    to="/contact"
                    href="#"
                    class="text-base font-medium hover:text-gray-900"
                  >
                    Hubungi Kami
                  </router-link>
                </span>
              </a>

              <div
                href=""
                class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
              >
                <svg
                v-if="isShowButton"
                  class="flex-shrink-0 h-6 w-6 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                <span
                  v-if="isShowButton"
                  @click="showFormOption = !showFormOption"
                  class="ml-3 text-base font-medium text-gray-900"
                >
                  Borang
                </span>
              </div>
            </nav>
          </div>
        </div>
        <div class="py-6 px-5 space-y-6">
          <div v-if="showFormOption" class="grid grid-cols-2 gap-y-4 gap-x-8">
            <a
              href="/KPSB-BorangPermohonanAnggota.pdf"
              class="text-base font-medium text-gray-900 hover:text-gray-700"
            >
              Permohonan Anggota
            </a>

            <a
              href="/KPSB-BorangWarisAnggota.pdf"
              class="text-base font-medium text-gray-900 hover:text-gray-700"
            >
              Waris Anggota
            </a>

            <a
              href="/KPSB-BorangPermohonanTambahSyerAnggota.pdf"
              class="text-base font-medium text-gray-900 hover:text-gray-700"
            >
              Tambah Syer Anggota
            </a>

            <a
              href="/KPSB-BorangPermohonanTebusSyerAnggota.pdf"
              class="text-base font-medium text-gray-900 hover:text-gray-700"
            >
              Tebus Syer Anggota
            </a>
          </div>
          <div
          v-if="isShowButton"
          >
            <a
              @click="logOut"
              href="#"
              class="
                w-full
                flex
                items-center
                justify-center
                px-4
                py-2
                border border-transparent
                rounded-md
                shadow-sm
                text-base
                font-medium
                text-white
                bg-green-500
                hover:bg-indigo-700
              "
            >
              Log Keluar
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";

export default {
  name: "Header2",

  componenets: {},

  data: () => ({
    showMenu: false,
    showFormOption: false,
    showMobileMenu: false,
    publicPath: process.env.BASE_URL,
    showForm: false,
  }),

  computed: {
    isShowButton() {
      const auth = getAuth().currentUser;
      if (auth) {
        // User is signed in.
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.showForm = true;
        return true;
      } else {
        // No user is signed in.
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.showForm = false;
        return false;
      }
    },
  },
  methods: {
    logOut() {
      const auth = getAuth();
      signOut(auth).then(() => {
        console.log("out");
      });
    },

    // isShowButton() {
    //   const auth = getAuth().currentUser;
    //   if (auth) {
    //     // User is signed in.
    //     this.showForm = true;
    //     return false;
    //   } else {
    //     // No user is signed in.
    //     this.showForm = false;
    //     return true;
    //   }
    // },
  },
};
</script>

<style>
.bg-green-fluorescent {
  background-color: #94de2a;
}
</style>
