import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import { initializeApp } from "firebase/app";

import firebaseConfig from "./firebase/firebase-config";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

initializeApp(firebaseConfig);
createApp(App).use(router, VueSweetalert2).mount("#app");
