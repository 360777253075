<template>
  <div>
    <Header />
    <AboutUs />
    <div class="mt-24 md:mt-48">
      <Product />
    </div>
    <Activity />
    <Awards />
    <HighMember />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import AboutUs from "../components/AboutUs.vue";
import Product from "../components/Product.vue";
import Activity from "../components/Activity.vue";
import Awards from "../components/Awards.vue";
import HighMember from "../components/HighMember.vue";
import Footer from "../components/Footer.vue";
import "../assets/tailwind.css";

export default {
  name: "Home",
  components: {
    Header,
    AboutUs,
    Product,
    Activity,
    Awards,
    HighMember,
    Footer,
  },
  // data: function() {
  //   // Put some data here
  // }
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */
</style>
