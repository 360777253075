<template>
  <footer class="bg-dark w-full">
    <div class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
      <p class="text-center text-base text-gray-400">
        &copy; Copyrights, Koperasi Pertanian Sabah. All rights reserved.
      </p>
    </div>
  </footer>
</template>
<style>
.bg-dark {
  background-color: #02292f;
}
</style>
