<template>
  <div id="product" class="w-full flex-col">
    <hr />
    <h1 class="px-10 font-bold text-2xl md:text-5xl text-black py-3 md:py-6">
      Produk
    </h1>
    <div class="flex flex-row px-10">
      <div class="rectangle-23 bg-green-fluorescent mt-1 mr-3"></div>
      <div class="w-full h-34 md:h-20">
        <p class="font-medium text-md md:text-xl">
          Hasil pertanian merupakan tunjang utama dalam meningkatkan ekonomi
          koperasi ini. Tanaman kelapa sawit, kelapa tacunan, dan penghasilan
          bibit tanaman antara contoh utama yang giat diusahakan.
        </p>
      </div>
    </div>
    <div
      class="w-full px-10 py-10 pb-15 flex flex-col md:flex-row grid grid-rows-1 md:grid-cols-3 gap-4"
    >
      <!-- Anak Benih Sawit -->
      <div class="rounded-3xl shadow">
        <div
          class="h-80 w-full rounded-t-3xl bgNoRepeatImage"
          v-bind:style="imageKelapaSawit"
        ></div>
        <div class="h-1/6 flex justify-center py-5">
          <h1 class="font-semibold text-xl">Anak Benih Sawit</h1>
        </div>
      </div>
      <!-- Anak Benih Kelapa Pandan -->
      <div class="rounded-3xl shadow">
        <div
          class="h-80 rounded-t-3xl bgNoRepeatImage"
          v-bind:style="imageKelapaPandan"
        ></div>
        <div class="h-1/6 flex justify-center py-5">
          <h1 class="font-semibold text-xl">Anak Benih Kelapa Pandan</h1>
        </div>
      </div>
      <!-- Musang King -->
      <div class="rounded-3xl shadow">
        <div
          class="h-80 bgNoRepeatImage rounded-t-3xl"
          v-bind:style="imageMusangKing"
        ></div>
        <div class="h-1/6 flex justify-center py-5">
          <h1 class="font-semibold text-xl">Anak Benih Musang King</h1>
        </div>
      </div>
      <!-- Anak Benih Sawit -->
      <div class="rounded-3xl shadow">
        <div
          class="h-80 w-full rounded-t-3xl bgNoRepeatImage"
          v-bind:style="imageAvocado"
        ></div>
        <div class="h-1/6 flex justify-center py-5">
          <h1 class="font-semibold text-xl">Anak Benih Avocado</h1>
        </div>
      </div>
      <!-- Anak Benih Kelapa Pandan -->
      <div class="rounded-3xl shadow">
        <div
          class="h-80 rounded-t-3xl bgNoRepeatImage"
          v-bind:style="imageTacunan"
        ></div>
        <div class="h-1/6 flex justify-center py-5">
          <h1 class="font-semibold text-xl">Anak Benih Kelapa Tacunan</h1>
        </div>
      </div>
      <!-- Musang King -->
      <div class="rounded-3xl shadow">
        <div
          class="h-80 bgNoRepeatImage rounded-t-3xl"
          v-bind:style="imageJackfruit"
        ></div>
        <div class="h-1/6 flex justify-center py-5">
          <h1 class="font-semibold text-xl">Anak Benih Nangka</h1>
        </div>
      </div>
    </div>
    <!-- <div
      class="h-0 md:h-screen px-10 w-full bg-cover bg-center"
      v-bind:style="imageQuote"
    ></div> -->
    <div class="px-10 w-full h-34 md:h-20">
      <p class="font-medium text-md md:text-xl">
        Sila 
        <router-link
          class="inline-block px-2 py-1 border border-green-500 text-green-500 font-medium text-md rounded hover:bg-green-500 hover:text-white transition-colors duration-300"
          to="/contact"
          >hubungi kami</router-link
        >
        untuk mendapatkan anak benih yang tersedia. Sebarang pertanyaan boleh di
        lakukan.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Product",
  computed: {
    kelapaPandan() {
      return require("@/assets/images/kelapa-pandan.jpeg");
    },
    kelapaSawit() {
      return require("@/assets/images/anak-benih-sawit.jpeg");
    },
    musangKing() {
      return require("@/assets/images/Durian.jpg");
    },
    kelapaTacunan() {
      return require("@/assets/images/benih-tacunan.jpg");
    },
    avocado() {
      return require("@/assets/images/Avocado-2.jpg");
    },
    jackfruit() {
      return require("@/assets/images/Nangka-J33.jpg");
    },
    projekNenas() {
      return require("@/assets/images/projek-nenas.jpeg");
    },
    quote() {
      return require("@/assets/images/quote-banner.png");
    },
    imageKelapaPandan() {
      return {
        background: `url(${this.kelapaPandan})`,
        backgroundSize: "cover !important",
        display: "block",
      };
    },
    imageKelapaSawit() {
      return {
        background: `url(${this.kelapaSawit})`,
        backgroundSize: "cover !important",
        display: "block",
      };
    },
    imageMusangKing() {
      return {
        background: `url(${this.musangKing})`,
        backgroundSize: "cover !important",
        display: "block",
      };
    },
    imageAvocado() {
      return {
        background: `url(${this.avocado})`,
        backgroundSize: "cover !important",
        display: "block",
      };
    },
    imageTacunan() {
      return {
        background: `url(${this.kelapaTacunan})`,
        backgroundSize: "cover !important",
        display: "block",
      };
    },
    imageJackfruit() {
      return {
        background: `url(${this.jackfruit})`,
        backgroundSize: "cover !important",
        display: "block",
      };
    },
    imageQuote() {
      return {
        background: `url(${this.quote})`,
        backgroundSize: "cover !important",
      };
    },
  },
};
</script>

<style>
.bg-green-fluorescent {
  background-color: #94de2a;
}
.shadow {
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
}
.rectangle-220 {
  border-radius: 20px;
}
.bgNoRepeatImage {
  background-repeat: no-repeat;
}
</style>