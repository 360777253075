<template>
  <div class="relative w-full" id="high-member">
    <h1 class="px-10 font-bold text-2xl md:text-5xl pt-20 md:pt-10 text-black py-3 md:py-6">
      Kepimpinan Tertinggi & Pengurusan
    </h1>
    <!-- Hierarchy -->
    <!-- Chong Tan Chun -->
    <div class="pt-10 md:pt-0 p-0 md:p-10 flex w-full col">
      <div class="-space-y-3 mx-auto">
        <img
          class="ml-5 object-cover w-20 h-20 rounded-full"
          src="@/assets/images/pengurusan/pengerusi.jpg"
          alt="Profile image"
        />
        <div class="border border-gray-400 rounded-lg bg-white">
          <div class="text-center px-8 py-3">
            <p class="text-lg font-xl">Encik Chong Tan Chun</p>
            <p class="font-light text-xs">PENGERUSI</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Divider -->
    <div class="px-10 py-10 md:pt-0">
      <div class=" w-full border-b border-gray-400"></div>
    </div>
    <div class="flex-col md:flex-row flex w-full">
      <!-- Frank Salazar -->
      <div class="pt-10 md:pt-0 p-0 md:p-10 flex w-full md:w-1/3 col">
        <div class="-space-y-3 mx-auto">
          <img
            class="ml-5 object-cover w-20 h-20 rounded-full"
            src="@/assets/images/pengurusan/tim_pengarah.jpg"
            alt="Profile image"
          />
          <div class="border border-gray-400 rounded-lg bg-white">
            <div class="text-center px-8 py-3">
              <p class="text-lg font-xl">Encik Frank Salazar</p>
              <p class="font-light text-xs">TIMB. PENGERUSI</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Jupri Demabela -->
      <div class="pt-10 md:pt-0 p-0 md:p-10 flex w-full md:w-1/3 col">
        <div class="-space-y-3 mx-auto">
          <img
            class="ml-5 object-cover w-20 h-20 rounded-full"
            src="@/assets/images/pengurusan/20211017_093012.jpg"
            alt="Profile image"
          />
          <div class="border border-gray-400 rounded-lg bg-white">
            <div class="text-center px-8 py-3">
              <p class="text-lg font-xl">Tn. Hj Jupri Bin Demabela</p>
              <p class="font-light text-xs">SETIAUSAHA</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Paken Anday -->
      <div class="pt-10 md:pt-0 p-0 md:p-10 flex w-full md:w-1/3 col">
        <div class="-space-y-3 mx-auto">
          <img
            class="ml-5 object-cover w-20 h-20 rounded-full"
            src="@/assets/images/pengurusan/paken.jpg"
            alt="Profile image"
          />
          <div class="border border-gray-400 rounded-lg bg-white">
            <div class="text-center px-8 py-3">
              <p class="text-lg font-xl">Tn. Hj Paken Bin Hj Anday</p>
              <p class="font-light text-xs">BENDAHARI</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Divider -->
    <div class=" py-10 px-10 pt-10 md:pt-00">
      <div class="w-full border-b border-gray-400"></div>
    </div>
    <div class="flex-col md:flex-row flex w-full">
      <!-- Idrus Shafie -->
      <div class="pt-10 md:pt-0 p-0 md:p-10 flex w-full md:w-1/3 col">
        <div class="-space-y-3 mx-auto">
          <img
            class="ml-5 object-cover w-20 h-20 rounded-full"
            src="@/assets/images/pengurusan/idrus.jpg"
            alt="Profile image"
          />
          <div class="border border-gray-400 rounded-lg bg-white">
            <div class="text-center px-8 py-3">
              <p class="text-lg font-xl">Datuk Idrus Bin Shafie</p>
              <p class="font-light text-xs">ANGGOTA LEMBAGA</p>
            </div>
          </div>
        </div>
      </div>
      <!-- MC Ismail -->
      <div class="pt-10 md:pt-0 p-0 md:p-10 flex w-full md:w-1/3 col">
        <div class="-space-y-3 mx-auto">
          <img
            class="ml-5 object-cover w-20 h-20 rounded-full"
            src="@/assets/images/pengurusan/mc_ismail.jpg"
            alt="Profile image"
          />
          <div class="border border-gray-400 rounded-lg bg-white">
            <div class="text-center px-8 py-3">
              <p class="text-lg font-xl">Datuk Hj MC Ismail Bin Salam</p>
              <p class="font-light text-xs">ANGGOTA LEMBAGA</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Abd Rahim Awang -->
      <div class="pt-10 md:pt-0 p-0 md:p-10 flex w-full md:w-1/3 col">
        <div class="-space-y-3 mx-auto">
          <img
            class="ml-5 object-cover w-20 h-20 rounded-full"
            src="@/assets/images/pengurusan/dr-Rahim.jpg"
            alt="Profile image"
          />
          <div class="border border-gray-400 rounded-lg bg-white">
            <div class="text-center px-8 py-3">
              <p class="text-lg font-xl">Dr Abdul Rahim Bin Awang</p>
              <p class="font-light text-xs">ANGGOTA LEMBAGA</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-col md:flex-row flex justify-center w-full">
      <!-- Lagani Sahid -->
      <div class="pt-10 md:pt-0 p-0 md:p-10 flex w-full md:w-1/3 col">
        <div class="-space-y-3 mx-auto">
          <img
            class="ml-5 object-cover w-20 h-20 rounded-full"
            src="@/assets/images/pengurusan/lagani_sahid.jpg"
            alt="Profile image"
          />
          <div class="border border-gray-400 rounded-lg bg-white">
            <div class="text-center px-8 py-3">
              <p class="text-lg font-xl">Tn Hj Lagani Bin Hj Sahid</p>
              <p class="font-light text-xs">ANGGOTA LEMBAGA</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Ashraf Khan -->
      <div class="py-10 md:py-0 p-0 md:p-10 flex w-full md:w-1/3 col">
        <div class="-space-y-3 mx-auto">
          <img
            class="ml-5 object-cover w-20 h-20 rounded-full"
            src="@/assets/images/pengurusan/ashraf.jpg"
            alt="Profile image"
          />
          <div class="border border-gray-400 rounded-lg bg-white">
            <div class="text-center px-8 py-3">
              <p class="text-lg font-xl">Encik Ashraf Bin Yacob Khan</p>
              <p class="font-light text-xs">ANGGOTA LEMBAGA</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HighMember",
  components: {},
  data() {
    return {};
  },
};
</script>
