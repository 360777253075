<template>
  <div>
    <div class="pl-4 pt-5 font-medium text-2xl">
      <span>PROFIL PENGGUNA</span>
    </div>
    <div class="flex pt-5 md:pt-10">
      <div
        class="
          h-full
          w-full
          bg-gray-100
          shadow-md
          ml-3
          md:ml-20
          mr-6
          rounded-xl
          p-5
          md:p-10
        "
      >
        <div class="flex flex-col md:flex-row">
          <div class="flex -space-x-2 overflow-hidden">
            <img
              v-if="user.image_url"
              @click="showSlideOver = !showSlideOver"
              class="
                inline-block
                m-3
                h-20
                w-20
                rounded-full
                ring-2 ring-green-500
              "
              :src="user.image_url"
              alt="avatar-default"
            />
            <img
              v-else
              @click="showSlideOver = !showSlideOver"
              class="
                inline-block
                m-3
                h-20
                w-20
                rounded-full
                ring-2 ring-green-500
              "
              src="@/assets/images/user.png"
              alt="avatar-default"
            />
          </div>
          <div class="flex flex-col">
            <div class="flex flex-col md:flex-row">
              <div class="font-Semibold text-xl md:text-3xl pr-0 md:pr-11">
                {{ user.full_name }}
              </div>
              <div class="flex flex-col">
                <div class="font-normal text-sm text-gray-300">
                  Jumlah Syer ({{
                    share.updated_at
                      ? dateTime(share.updated_at.toDate())
                      : dateTime(new Date())
                  }})
                </div>
                <div class="font-Semibold text-lg md:text-2xl">
                  RM{{ share.total_share }}
                </div>
              </div>
              <div class="flex flex-col pl-0 md:pl-10">
                <div class="font-normal text-sm text-gray-300">No. Ahli</div>
                <div class="font-Semibold text-lg md:text-2xl">
                  {{ share.member_no }}
                </div>
              </div>
            </div>
            <!-- <div class="font-normal text-sm text-gray-300">
              Tukar gambar profil
            </div> -->
            <div class="flex flex-col md:flex-row pt-0 md:pt-4 w-full">
              <div class="flex flex-col pr-16">
                <div class="font-normal text-sm text-gray-300">
                  No kad pengenalan
                </div>
                <div class="font-Semibold text-lg md:text-2xl">
                  {{ user.ic_no }}
                </div>
              </div>
              <div class="flex flex-col pr-10">
                <div class="font-normal text-sm text-gray-300">Alamat</div>
                <div class="font-Semibold text-lg md:text-2xl">
                  {{ user.address }}
                </div>
              </div>
              <div class="flex flex-col">
                <div class="font-normal text-sm text-gray-300">No. Akaun</div>
                <div class="font-Semibold text-lg md:text-2xl">
                  {{ user.bank_account }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "@/firebase/db";
import moment from "moment";
// import { getDownloadURL, getStorage, ref } from "@firebase/storage";

export default {
  data() {
    return {
      user: {},
      share: {},
    };
  },
  async created() {
    this.checkUserState();
  },
  methods: {
    checkUserState() {
      const auth = getAuth();
      // const storage = getStorage();

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // const storageRef = ref(storage, user.uid + ".jpeg");

          await onSnapshot(doc(db, "users", user.uid), async (userDocSnap) => {
            this.user = userDocSnap.data();
            if (userDocSnap.exists) {
              console.log(userDocSnap.data());
              this.user = userDocSnap.data();
              if (this.user.share_id) {
                const shareInformationDocSnap = await getDoc(
                  doc(db, "shares", this.user.share_id)
                );
                if (shareInformationDocSnap.exists) {
                  this.share = shareInformationDocSnap.data();
                  console.log(this.share);
                }
              }
            }
          });
          // getDownloadURL(storageRef).then((url) => {
          //   console.log(url);
          //   this.user.image_url = url;
          // });
        } else {
          console.log("Not authenticated");
          this.$router.push("/");
        }
      });
    },
    dateTime(value) {
      return moment(value).format("DD, MMM YYYY");
    },
  },
};
</script>

<style></style>
