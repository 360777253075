<template>
  <div>
    <Header2 />
    <Maps />
    <Footer />
  </div>
</template>

<script>
import Header2 from "../components/Header2.vue";
import Maps from "../components/ContactMaps.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Contact",
  components: {
    Header2,
    Maps,
    Footer
  },
  data: function() {
  }
};
</script>

<style>
</style>
